import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

export const Home = () => {
	const [tbanners, setTbanners] = useState([])
	const [obanners, setObanners] = useState([])
	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(true);
    let headerid = 'headerhome'

    useEffect(() => {
        callMenuPull()
    }, [])

    const callMenuPull = () => {
        setLoading(true);
        let Rno = Math.floor(Math.random() * 1000) + 1 ;
        fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=HOME')
        .then(response => {
            return response.json()
        })
        .then(data => {
            let results = data
            setTbanners(results.data)
			setObanners(results.banners)
			setProducts(results.products)
			setLoading(false);
			//loadScript('/assets/js/owl.carousel.js');
			loadScript('/assets/js/script.js');

        })
        .catch((error) =>{
            alert(error)
        })
        
    }

	var loadScript = function(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        document.body.appendChild(script);
    }



  return (
    <div>
	{(loading) ? (
		<div align="center"><img src="/assets/images/sp-loading.gif" alt="Loading please wait...."/></div>
	):(
	<>
	<section>
		<div className="container">
			<div id="carouselHome" className="carousel slide carousel-fade" data-ride="carousel">
				<ol className="carousel-indicators hidden-xs">
					<>
					{tbanners.length > 0 && (
						<>
						{tbanners.map(prod => (
							<li data-target="#carouselHome" data-slide-to={prod.no} className={(prod.no === 0 ? 'active' : '')}></li>
						))}
						</>
					)}
					</>	
                </ol>
				<div className="carousel-inner">
					<>
					{tbanners.length > 0 && (
						<>
						{tbanners.map(prod => (
							<>
							<div className={(prod.no === 0 ? 'item active' : 'item')}>
								<Link to={prod.url}>
								<picture>
									<source type="image/webp" srcset={prod.banwebp}/>
									<source type="image/jpeg" srcset={prod.banjpg}/>
									<img id="slider-item1" src={prod.banjpg} alt={prod.name} />
								</picture>
								</Link>
							</div>
							</>
						))}
						</>
					)}
					</>
					
								
                </div>
			</div>
		</div>
	</section>
	<section>
		<div className="container pt10 pb10 txt-center">
			<h4> - SHIPPING WORLDWIDE NOW - </h4>
		</div>
	</section>
	<section id="content">
		<div className="container">
			<div className="row">
				<>
				{obanners.length > 0 && (
					<>
					{obanners.map(prod => (
						<>
						<div className={(prod.no === 0 || prod.no === 1 ? 'col-sm-6 pb20' : 'col-sm-4 pb20')}>
							<Link to={prod.url} title={prod.url} >
							<picture>
							<source type="image/webp" srcset={prod.banwebp}/>
							<source type="image/jpeg" srcset={prod.banjpg}/>
							<img src={prod.banjpg} alt="banner"/>
							</picture></Link>
						</div>
						</>
					))}
					</>
				)}
				</>
			</div>
		</div>
	</section>
	<section>
		<div className="container pb10 pt0 txt-center">
			<h4> - NEW ARRIVALS - </h4>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-sm-12">
					<div className="product-carousel" id="product-carousel01">
						<div className="owl-carousel">
						<>
						{products.length > 0 && (
							<>
							{products.map(prod => (
								<>
								<div className="article">
									<Link to={prod.url}>
										<div className="product_image text-center">
										<picture>
										<source type="image/webp" srcset={prod.imgwebp}/>
										<source type="image/jpeg" srcset={prod.imgjpg}/>
										<img src={prod.imgjpg} alt="banner"/>
										</picture>
										</div>
									</Link>
								</div>
								</>
							))}
							</>
						)}
						</>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>    
	</>
	)}
    </div>
	
  )
}
