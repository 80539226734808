import React, {useEffect, useState} from 'react' 
import parse from 'html-react-parser';

export const About = () => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(null);

    useEffect(() => {
        setLoading(true);
        callDataPull();
        
        //loadImgs(prodid,imgs);
    }, [])

    const callDataPull = () => {
        let Rno = Math.floor(Math.random() * 1000) + 1 ;
        fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=PAGE&opt=aboutus')
        .then(response => {
            return response.json()
        })
        .then(data => {
            let results = data
            setPage(parse(results.data))
            setLoading(false);
            loadScript('/assets/js/jquery.mCustomScrollbar.concat.min.js');
            
        })
        .catch((error) =>{
            alert(error)
        })
        
    }

    var loadScript = function(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        document.body.appendChild(script);
    }


  return (
    <div>
        <section id="content" className="wow fadeInUp">
            <div className="container">
                <div className="about-payal-singhal">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="payal-photo">
                                <img src="assets/images/Payal-Singhal-Fashion-Designer.jpg" />
                            </div>
                        </div>
                        <div className="col-sm-7">
                            {(loading) ? (
                                <div align="center"><img src="/assets/images/sp-loading.gif" alt="Loading please wait...."/></div>
                            ):(
                                <>
                                <div><>{page}</></div>
                                </>
                            )}
                                
                        </div>
                    </div>
                </div>
            </div>
        </section>   


    </div>
  )
}
