import React, { useEffect, useState } from 'react' 
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom' 
import Select from 'react-select'
//import { GalleryDiv } from './GalleryDiv'

export const ProductDetail = () => {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([])
    const [prodlist, setProdlist] = useState([])
    const { cat, prodid } = useParams();
    const [imgs, setImgs] = useState([])
    const [sizes, setSizes] = useState([])

    const cnts = ['a','b']

    const optQty = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' }
    ]

    useEffect(() => {
        setLoading(true);
        callDataPull(prodid,cat);
        
        //loadImgs(prodid,imgs);
    }, [prodid])

    const callDataPull = (prodid,cat) => {
        setLoading(true);
        //let catid = 18
        let Rno = Math.floor(Math.random() * 1000) + 1 ;
        fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=PRODUCT&opt='+prodid+'&pcat='+cat)
        .then(response => {
            return response.json()
        })
        .then(data => {
            let results = data
            //alert(results.status)
            //console.log(results)
            setProduct(results.data)
            setSizes(results.sizes)
            setImgs(results.imgs)
            setProdlist(results.prodlist)
            //
            
            
            setLoading(false);
            //alert('data pulled');
            //loadScript('/assets/unitegallery/js/jquery-11.0.min.js');
            //loadScript('/assets/unitegallery/js/unitegallery.min.js');
            //loadScript('/assets/unitegallery/themes/compact/ug-theme-compact.js');
            loadScript('/assets/js/scriptz.js');
            //loadScript('/assets/js/owl.carousel.js');
			loadScript('/assets/js/script.js');
            
        })
        .catch((error) =>{
            alert(error)
        })
        
    }

    var loadScript = function(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        document.body.appendChild(script);
    }

    function handleSubmit(e) {
        //alert(e.sizes.value);
        e.preventDefault();
        alert('You clicked submit.');
    }

    /* var loadImgs = function(prodid,imgs) {
        const parent = document.getElementById('gallery');
        if(imgs.length >0){
            imgs.forEach((cnt) => {
                const img = document.createElement("img");
                img.src = `https://x3w9e2m3.stackpathcdn.com/collection/${prodid}-${cnt}3.jpg`;
                img.async = true;

                parent.appendChild(img);
            })
        }
    }
 */


    return (
        <div>
        {(loading) ? (
            <div align="center"><img src="/assets/images/sp-loading.gif" alt="Loading please wait...."/></div>
        ):(
        <>
        <section id="content">
        
    	<div className="container">
        	<div className="details-page">
                <div className="row">
                    
                    <div className="col-md-5 col-sm-6">
                        
                        <div id="gallery" style={{ 'display': 'none' }}>
                            {/* <GalleryDiv name={prodid} url={prodid} imgs={cnts}/>
                            <img alt={prodid}
                                src={`https://x3w9e2m3.stackpathcdn.com/collection/${prodid}-a3.jpg`}
                                data-image={`https://x3w9e2m3.stackpathcdn.com/collection/${prodid}-a0.jpg`}
                                data-description={prodid} /> */}
                            {imgs.length > 0 && (
                            <>
                            {imgs.map(cnt => (
                            <>
                            <img alt={product.alt} src={`https://x3w9e2m3.stackpathcdn.com/collection/${prodid}-${cnt}3.jpg`} 
                            data-image={`https://x3w9e2m3.stackpathcdn.com/collection/${prodid}-${cnt}0.jpg`}
                            data-description={product.name}
                            />
                            
                            </>
                            ))}
                            </>
                            )}
                            {product.video !=='' && (
                            <>
                            <img alt={product.name}
								 data-type="youtube"
								 data-videoid={product.video}
								 data-title={product.name}
								 data-description={product.name}/>	
                            </>
                            )} 

                        </div>

						<div className="social-links pt10" style={{textAlign: 'center' }}>
                            <Link to="https://api.whatsapp.com/send?text=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="whatsapp" title="whatsapp"><i className="fa fa-whatsapp"></i></Link>
                            <Link to="http://www.facebook.com/sharer.php?u=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="Facebook" title="Facebook"><i className="fa fa-facebook"></i></Link>
                            <Link to="https://twitter.com/share?url=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="Twitter" title="Twitter"><i className="fa fa-twitter"></i></Link>
                             <Link to="mailto:?&subject=&body=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="Mail" title="Mail"><i className="fa fa-envelope-o"></i></Link>
                            <Link to="https://pinterest.com/pin/create/bookmarklet/?media=https://www.payalsinghal.com/collection/lime-green-colour-embroidered-choli-with-lehenga-a0.jpg&url=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="pinterest" title="pinterest"><i className="fa fa-pinterest"></i></Link> 
                            <Link to="https://plus.google.com/share?url=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="Google+" title="Google+"><i className="fa fa-google-plus"></i></Link>
                            <Link to="http://www.tumblr.com/share/link?url=https:https://www.payalsinghal.com/product/lime-green-colour-embroidered-choli-with-lehenga" target="_blank" alt="Tumblr" title="Tumblr"><i className="fa fa-tumblr"></i></Link>
						</div>
						
                    </div>
                    <div className="col-md-7 col-sm-6">
						<div className="product-details">
                            <div className="product-info">
								<div className="pb10">
									<span className="breadcrumbs"><Link to="https://www.payalsinghal.com/">Home</Link>  &nbsp; 
                                    <Link to="new-arrivals">New Arrivals</Link></span>
								</div>

								<div>
									<h1 className="name"><Link to="https://www.payalsinghal.com/new-arrivals/lime-green-colour-embroidered-choli-with-lehenga">{product.name}</Link></h1>
									<h2 className="code">{product.code}</h2>
									<h2 className="price"><i className="fa fa-inr" aria-hidden="true"></i>{product.price}</h2>					
									<h3 className="desc">{product.desc}</h3>
								</div>
                            </div>
                            <form method="get" name="frmsrch" onSubmit={handleSubmit}>
                            <input type="hidden" id="retnto" value=""/>
							<input type="hidden" id="carteventid" value="Cart.1645445269.2138"/>
							<input type="hidden" id="prodid" value={product.id}/>
							<input type="hidden" id="prodmeas" value="2"/>
							<input type="hidden" id="measlist" value=""/>
							<input type="hidden" id="prodcolor" value="Lime Green, Lemon Yellow"/>
                            <div className="order-info">
								<div className="btn-group size">
                                    <Select id="prodsize" options={sizes}/>
                                </div>
                                <span className="size-guide"><Link to="">SIZE GUIDE</Link></span>
								&nbsp; OR &nbsp;
								<span className="size-guide">
									<Link to="mymeasurements?prodid=2138&amp;id=2">CUSTOM MAKE TO YOUR SIZE</Link>
								</span>
							</div>
                            <div className="btn-group qty pb10">
                                <Select id="prodqty" options={optQty}/>
							</div>
                            <div className="size-guide pb10">
                            	<Link to="">HAVE A QUESTION?</Link>
                            </div>
                            <div className="buttons pt10">
                            	<button className="btn btn-default cart-btn" type="submit">ADD TO BAG</button>
                                <button className="btn btn-default checkout-btn" type="button" onClick={handleSubmit}>ADD+CHECKOUT</button>
                            </div>
                            <div className="wishlist" id="wish2138">
                            	ADD TO WISHLIST&nbsp;&nbsp; 
                                <Link to="" className="wishbtn"><i className="fa fa-heart-o"></i></Link>
                            </div>
                            </form>
							{/* <form name="frmprod" method="post" action="addtocart" onsubmit='return doaddcart()'>
							<input type="hidden" id="retnto" value=""/>
							<input type="hidden" id="carteventid" value="Cart.1645445269.2138"/>
							<input type="hidden" id="prodid" value={product.id}/>
							<input type="hidden" id="prodmeas" value="2"/>
							<input type="hidden" id="measlist" value=""/>
							<input type="hidden" id="prodcolor" value="Lime Green, Lemon Yellow"/>
                            <div className="order-info">
								<div className="btn-group size">
                                    <Select id="prodsize" options={sizes}/>
                                </div>
								<span className="size-guide"><Link data-toggle="modal" data-target="#sizeChartModal">SIZE GUIDE</Link></span>
								OR 
								<span className="size-guide">
									<Link to="mymeasurements?prodid=2138&amp;id=2">CUSTOM MAKE TO YOUR SIZE</Link>
								</span>
							</div>
							<div className="btn-group qty pb10">
								
								<select className="styled" id="prodqty">
								<option value=''>Qty</option>
								<option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option>								
                                </select>
							</div>
							<div className="size-guide pb10">
                            	<Link data-toggle="modal" data-target="#QuesModal">HAVE A QUESTION?</Link>
                            </div>
                            <div className="buttons pt10">
                            	<button className="btn btn-default cart-btn" type="submit">ADD TO BAG</button>
                                <button className="btn btn-default checkout-btn" type="button" onclick='doaddcheck()'>ADD+CHECKOUT</button>
                            </div>
							<div className="wishlist" id="wish2138">
                            	ADD TO WISHLIST <Link to="" className="wishbtn"><i className="fa fa-heart-o"></i>
                                </Link>
                            </div>
							</form> */}
                            <ul className="care-inst pt10">
                                <li><strong>WHAT'S IN THE BOX: </strong>1 Choli, 1 Lehenga</li>
                                <li><strong>WASH CARE: </strong>Dry Clean Only</li>
                                <li><strong>SHIPPING: </strong>
								Usually ships in 3-4 weeks from the date of purchase. <Link to="terms-conditions#shipping" className="fw-500" target="_blank"><u>Learn more</u></Link>. 
								<br/>*Prices are inclusive of all taxes, packaging and handling.<br/>For international purchases, duties and taxes may be applicable based on the import laws of your country.</li>
								<li><strong>MANUFACTURED BY: </strong>Payal Singhal Design House LLP</li>
								<li><strong>PACKED BY: </strong>Payal Singhal Design House LLP</li>
								<li><strong>COUNTRY OF ORIGIN: </strong>India</li>
                            </ul>
                        </div>
                    </div>	
                    
                </div>
            </div>
        </div>
        </section>  
        <section>
            <div className="container pb10 pt0 txt-center">
                <h4> - BEST SELLERS - </h4>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="product-carousel" id="product-carousel01">
                            <div className="owl-carousel">
                            <>
                            {prodlist.length > 0 && (
                                <>
                                {prodlist.map(prod => (
                                    <>
                                    <div className="article">
                                        <Link to={prod.url}>
                                            <div className="product_image text-center">
                                            <picture>
                                            <source type="image/webp" srcset={prod.imgwebp}/>
                                            <source type="image/jpeg" srcset={prod.imgjpg}/>
                                            <img src={prod.imgjpg} alt="banner" loading="lazy"/>
                                            </picture>
                                            </div>
                                        </Link>
                                    </div>
                                    </>
                                ))}
                                </>
                            )}
                            </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        </>
        )}
        </div>
    )
}
