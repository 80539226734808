import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import parse from 'html-react-parser';

export const Contact = () => {
  const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(null);

    useEffect(() => {
        setLoading(true);
        callDataPull();
        
        //loadImgs(prodid,imgs);
    }, [])

    const callDataPull = () => {
        let Rno = Math.floor(Math.random() * 1000) + 1 ;
        fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=PAGE&opt=contact')
        .then(response => {
            return response.json()
        })
        .then(data => {
            let results = data
            setPage(parse(results.data))
            setLoading(false);
        })
        .catch((error) =>{
            alert(error)
        })
        
    }

    var loadScript = function(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        document.body.appendChild(script);
    }

  return (
    <div>
    <section id="content">
        <div className="container">
            <div className="about-payal-singhal">
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        <div className="page-header ">
                            <h3>Contact</h3>
                        </div>
                        <div>
                        {(loading) ? (
                            <div align="center"><img src="/assets/images/sp-loading.gif" alt="Loading please wait...."/></div>
                        ):(
                            <>{page}</>
                        )}  			
                        </div>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </div>
    </section>          





    </div>
  )
}
