import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

export const Header = () => {
    const [topmenu, setTopmenu] = useState([])
    let headerid = 'headerhome'

    useEffect(() => {
        callMenuPull()
    }, [])

    const callMenuPull = () => {
        //setLoading(true);
        let Rno = Math.floor(Math.random() * 1000) + 1 ;
        fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=MENU')
        .then(response => {
            return response.json()
        })
        .then(data => {
            let results = data
            setTopmenu(results.data)
        })
        .catch((error) =>{
            alert(error)
        })
        
    }

  return (
    <div>
        <header id={headerid}>
            <div className="topbar hidden-xs">
                <div className="container">
                    <ul>
                        <li className="search search-toggle">
                            <Link to="search" alt="Search" title="Search">Search <span className="glyphicon glyphicon-search">
                                <span className="sr-only">Search</span>
                            </span>
                            </Link>
                        </li>
                        <li className="wishlist"><Link to="wishlist" alt="Wishlist" title="Wishlist">Wishlist<img src="https://x3w9e2m3.stackpathcdn.com/images/wishlist-icon.png" alt="Wishlist" width="16" className="ml5" /></Link></li>
                        <li className="shoppingbag"><Link to="shopping-cart" alt="My Bag" title="My Bag">My Bag<span id="mybagcnt">&nbsp;</span></Link></li>
                        <li className="signin">
                        <Link to="login" alt="Sign Up/In" title="Sign Up/In">Sign Up/In</Link>					
                        </li>
                    </ul>
                </div>
            </div>
            <nav className="navbar navbar-default">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/" alt="Home" title="Home"><img src="https://x3w9e2m3.stackpathcdn.com/images/logo.png" alt="Home"/></Link>
                    </div>
                </div>
            </nav>
            <div id="menubar">
                <div className="container">
                    <div className="collapse navbar-collapse" id="navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li className="dropdown">
                                <Link to="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Shop By Category</Link>
                                <ul className="dropdown-menu">
                                <>
                                {topmenu.length > 0 && (
                                    <>
                                    {topmenu.map(menu => (
                                        <li><Link to={menu.url} alt={menu.name} title={menu.name}>{menu.name}</Link></li>
                                    ))}
                                    </>
                                )}
                                </>
                                    
                                    {/* <li><Link to="pret-collection" alt="Pret Collection" title="Pret Collection">Pret Collection</Link></li>
                                    <li><Link to="off-the-runway" alt="Off the Runway" title="Off the Runway">Off the Runway</Link></li>
                                    <li><Link to="athleisure" alt="Athleisure" title="Athleisure">Athleisure</Link></li>
                                    <li><Link to="indian-wear" alt="Indian Wear" title="Indian Wear">Indian Wear</Link></li>
                                    <li><Link to="bridal-wear" alt="Bridal Wear" title="Bridal Wear">Bridal Wear</Link></li>
                                    <li><Link to="western-wear" alt="Western Wear" title="Western Wear">Western Wear</Link></li><li><Link to="menswear" alt="Menswear" title="Menswear">Menswear</Link></li>
                                    <li><Link to="kids-wear" alt="Kids Wear" title="Kids Wear">Kids Wear</Link></li>
                                    <li><Link to="accessories" alt="Accessories" title="Accessories">Accessories</Link></li>
                                    <li><Link to="celebs-in-ps" alt="Celebs in PS" title="Celebs in PS">Celebs in PS</Link></li>
                                    <li><Link to="masks" alt="Masks" title="Masks">Masks</Link></li>
                                    <li><Link to="gift-cards" alt="Gift Cards" title="Gift Cards">Gift Cards</Link></li>
                                    <li><Link to="search" alt="All" title="All">All</Link></li> */}                            
                                </ul>
                            </li>
                            {/* <!-- <li ><Link to="ps-fundraiser" title="PS Fundraiser" alt="PS Fundraiser">PS Fundraiser</Link></li> --> */}
                            <li ><Link to="/new-arrivals" title="New Arrivals" alt="New Arrivals">New Arrivals</Link></li>
                            {/* <!-- <li ><Link to="festive-wear" title="Festive Wear" alt="Festive Wear">Festive Wear</Link></li> --> */}
                            <li ><Link to="/sale" title="Sale" alt="Sale">Sale</Link></li>
                            {/* <!-- <li ><Link to="rakhi" title="Rakhi" alt="Rakhi">Rakhi</Link></li> --> */}
                            <li ><Link to="about" title="About PS" alt="About Us">About Us</Link></li>
                            <li ><Link to="ps-diary" title="PS Diary" alt="PS Diary">PS Diary</Link></li>
                            <li ><Link to="press" title="PS Press">PRESS</Link></li>
                            <li ><Link to="ps-collaborations" title="PS Collaborations">Collaborations</Link></li>
                            {/* <!-- <li></li> --> */}
                            <li className="wishlist visible-xs"><Link to="wishlist" alt="Wishlist" title="Wishlist">Wishlist<img alt="Wishlist" src="https://x3w9e2m3.stackpathcdn.com/images/wishlist-icon.png" width="16" className="ml5" /></Link></li>
                            <li className="shoppingbag visible-xs"><Link to="shopping-cart" alt="My Bag" title="My Bag">My Bag<span>&nbsp;</span></Link></li>
                            <li className="signin visible-xs">
                            <Link to="login" alt="Sign Up/In" title="Sign Up/In">Sign Up/In</Link></li>
                            <li className="signin visible-xs search-toggle"><Link to="search" alt="Search" title="Search">Search</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>



    </div>
  )
}
