import React from 'react'
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
        <footer id="footer">
        <div className="container">
            <div className="row"><div className="col-sm-12 pt30 pb20 ">
                <div className="col-sm-2 col-xs-12 pl50 divborder">
                    <div className="pt10">
                        <Link to="aboutus" alt="About Us" title="About Us">About Us</Link><br/>
                        <Link to="press" alt="Press" title="Press">Press</Link><br/>
                        {/* <!-- <Link to="#" alt="Collaborations" title="Collaborations">Collaborations</Link><br/>
                        <Link to="#" alt="Media" title="Media">Media</Link><br/>
                        <Link to="#" alt="Work with us" title="Work with us">Work with us</Link><br/> --> */}
                        <Link to="contact" alt="Contact us" title="Contact us">Contact us</Link><br/>
                        <Link to="customer-service" alt="Customer Service" title="Customer Service">Customer Care</Link><br/>
                    </div>
                </div>
                <div className="col-sm-2 col-xs-12 pl50 divborder">
                    <div className="pt10">
                        <Link to="new-arrivals" alt="New Arrivals" title="New Arrivals">New Arrivals</Link><br/>
                        {/* <!-- <Link to="rakhi" alt="Rakhi" title="Rakhi">Rakhi</Link><br/> --> */}
                        <Link to="gift-cards" alt="Gift Cards" title="Gift Cards">Gift Cards</Link><br/>
                        <Link to="sale" alt="Sale" title="Sale">Sale</Link><br/>
                        <Link to="masks" alt="Masks" title="Masks">Masks</Link><br/>
                        <Link to="western-wear" alt="Western Wear" title="Western Wear">Western Wear</Link><br/>
                        
                    </div>
                </div>
                <div className="col-sm-3 col-xs-12 pl50 divborder">
                <div className="pt10">
                    <Link to="ss21" title="Spring Summer'21">Spring Summer'21</Link><br/>
                    <Link to="fw21" title="Festive Winter'21">Festive Winter'21</Link><br/>
                    <Link to="ps-collaborations" title="PS Collaborations">Collaborations</Link><br/>
                    {/* <!-- <Link to="trunkshow" alt="#PSTravellingTrunkShow" title="#PSTravellingTrunkShow">#PSTravellingTrunkShow</Link><br/>
                    <Link to="#" alt="Shipping, Returns & Exchange Policy" title="Shipping, Returns & Exchange Policy">Shipping, Returns & Exchange Policy</Link><br/>
                    <Link to="#" alt="Measurement Guide" title="Measurement Guide">Measurement Guide</Link><br/>
                    <Link to="#" alt="FAQ's" title="FAQ's">FAQ's</Link> --> */}
                </div>
                </div>

                <div className="col-sm-5 col-xs-12 pt10 pb20 pl50">
                <h4 className="text-uppercase">STAY CONNECTED</h4>
                <div className="pt10">
                    <p>Be the first to know about sales, special promotions, tips, trends and more. </p>
                    <form data-form-output="form-output-global" data-form-type="subscribe" name="frmsubscr" method="get" action="#" className="rd-mailform form-classic form-inline pb10">
                    <div>
                        <div className="form-group">
                            <input type="hidden" name="newsemailflag" value="1"/>
                            <input type="text" className="form-control" name="newsemail" placeholder="Enter Your email address"/>
                        </div>
                    </div>
                    <button type="button" aria-label="Subscribe" className="btn btn-icon-only btn-primary" onclick="if(document.frmsubscr.newsemail.value==''){showmsg('Enter Your email address');document.frmsubscr.newsemail.focus()}else{document.frmsubscr.submit();}"><span className="fa fa-envelope-o"></span></button>
                    </form>
                    {/* <!-- <div className="pt20">
                    <h4 className="text-uppercase"><Link to="#">STORE LOCATOR</Link></h4>
                    </div> --> */}
                </div>
                </div>
                
            </div></div>
            </div>
        
    </footer>
    <footer id="footerbot">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="col-sm-7 col-xs-12 pb20 pl50">
                        <div className="pt10">
                        CUSTOMER CARE: <Link to="mailto:cs@payalsinghal.com" className="cust-service" alt="Customer Service" title="Customer Service">cs@payalsinghal.com</Link> <br/>
                        <Link to="privacy-policy" alt="PRIVACY &amp; COOKIES POLICY" title="PRIVACY &amp; COOKIES POLICY">PRIVACY &amp; COOKIES POLICY</Link>
                        | <Link to="terms-conditions" alt="Terms &amp; Conditions" title="Terms &amp; Conditions">TERMS &amp; CONDITIONS</Link>
                        <br/><Link to="shipping-information" alt="SHIPPING INFORMATION" title="SHIPPING INFORMATION">SHIPPING INFORMATION</Link>
                        <br/><Link to="returns-exchange-policy" alt="RETURNS, CANCELLATION AND EXCHANGE POLICY" title="RETURNS, CANCELLATION AND EXCHANGE POLICY">RETURNS, CANCELLATION AND EXCHANGE POLICY</Link>
                        <br/><Link to="refund-policy" alt="REFUND POLICY" title="REFUND POLICY">REFUND POLICY</Link>
                        </div>
                    </div>
                    <div className="col-sm-5 col-xs-12 pb20 pl50">

                        <h4 className="text-uppercase pt10">Follow Us</h4>
                        <div className="social-links pt10">
                        <Link to="https://twitter.com/payalsinghal" target="_blank" alt="Twitter" title="Twitter"><i className="fa fa-twitter"></i></Link>
                        <Link to="https://www.facebook.com/PayalSinghalInc/" target="_blank" alt="Facebook" title="Facebook"><i className="fa fa-facebook"></i></Link>
                        <Link to="https://www.instagram.com/payalsinghal/" target="_blank" alt="Instagram" title="Instagram"><i className="fa fa-instagram"></i></Link>
                        </div>
                        <div className="pt20 pb20">
                        <span>&copy; 2022 Payal Singhal Design House. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>



    </div>
  )
}
