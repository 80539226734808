import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom' 
import { useParams } from 'react-router-dom'
import { ProductDiv } from './ProductDiv'
import Select from 'react-select'




export const Products = () => {
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true);   
    const [opttype1, setOpttype1] = useState([])
    const [opttype2, setOpttype2] = useState([])
    const [opttype3, setOpttype3] = useState([])
    const [listcount, setListcount] = useState('')
    const [listtitle, setListtitle] = useState('')
    const [valtype1, setValtype1] = useState(0)
    const [valtype2, setValtype2] = useState(0)
    const { cat } = useParams();
    let catid = 0
    let type1=0
    let type2=0
    let type3=0
    let type4=0
    

    
    const [opttypes, setOpttypes] = useState({
        type1:0,
        type2:0,
        type3:0,
        type4:'',
        type5:''
    })

    const handler1 = (e) => {
        //alert(e.id)
        type1 = e.value
        setValtype1(type1)
        type2=valtype2
        callDataPull(cat,type1,type2)
    }

    const handler2 = (e) => {
        type2 = e.value
        setValtype2(type2)
        type1=valtype1
        callDataPull(cat,type1,type2)
    }
    
    const handler = (e) => {
        
        const name = e.label;
        const value = e.value;
        console.log(name, value);
        setOpttypes({...opttypes, [name]: value});
        //alert(opttypes.type1);
        //setType1(e.value)
        callDataPull()
    }

    const opttype4 = [
        { value: 'sorth2l', label: 'Price: High to Low' },
        { value: 'sortl2h', label: 'Price: Low to High' }
    ]

    useEffect(() => {
        callDataPull(cat)
    }, [cat])

    const callDataPull = (cat,type1,type2,type3,type4) => {
        //alert(cat)
        setLoading(true);
        let Rno = Math.floor(Math.random() * 1000) + 1 ;
        fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=LIST&cat='+cat+'&t1='+type1+'&t2='+type2+'&t3='+type3+'&t4='+type4)
        .then(response => {
            return response.json()
        })
        .then(data => {
            let results = data
            //alert(results.status)
            setProducts(results.data)
            setOpttype1(results.type1)
            setOpttype2(results.type2)
            setOpttype3(results.type3)
            setListcount(results.listcount)
            setListtitle(results.listtitle)
            setLoading(false);
        })
        .catch((error) =>{
            alert(error)
        })
        
    }

    


  return (
    <div>
        <section id="content" className="">
            <div className="container">
                {(loading) ? (
                    <div align="center"><img src="/assets/images/sp-loading.gif" alt="Loading please wait...."/></div>
                ):(
                <>
                <div className="page-header pb15">
                    <div className="show-left">
                    <h3>{listtitle}</h3>
                    </div><div className="pt5 pl15 show-left"> <h5>{listcount} Products Found</h5></div>
                </div>
                <div className="col-xs-12 pb15">
                    <span className="breadcrumbs"><Link to="">Home</Link>  &rarr; <Link to="">{listtitle}</Link></span>
                </div>
                

                <div className="product-sorting">
                    <form method="get" name="frmsrch">
                    <span className="sort-label">Sort By</span>
                    <div className="btn-group sort-type01">
                        <Select name="type1" id="type1" options={opttype1} value={valtype1} onChange={handler1}/>
                    </div>
                    <div className="btn-group sort-type02">
                        <Select name="type2" id="type2" options={opttype2} value={valtype2} onChange={handler2}/>
                    </div>
                    <div className="btn-group sort-type03">
                    <Select name="type3" id="type3" options={opttype3} value={opttypes.type3} onChange={handler}/>    
                    </div>
                    <div className="btn-group sort-type01">
                        <Select name="type4" id="type4" options={opttype4} value={opttypes.type4} onChange={handler}/>   
                    </div>
                    {/* <div className="btn-group sort-type01">
                    <select className="form-control"
                        name="type5"
                        value={opttypes.type5}
                        onChange={handler}>
                        {opttype4.map(o => (
                        <option key={o.value} value={o.value}>{o.label}</option>
                        ))}
                    </select>
                    </div> */}
                    {/* <!-- <div className="btn-group sort-type03">
                    <input type="text" className="form-control" name="q" value="" id="qsrch" size="20" placeholder="Search">
                    </div> --> */}
                    </form>
                </div>
                <div className="collection-page">
                        <>
                        {products.length > 0 && (
                            <>
                            {products.map(prod => (
                                
                                prod.no % 4 === 0 ? (
                                    <>
                                    <div className="row"></div>
                                    <ProductDiv key={prod.id} id={prod.id} name={prod.name} url={prod.url} price={prod.price} cnt={prod.cnt} video={prod.video} cat={cat} imgwebp={prod.imgwebp} imgjpg={prod.imgjpg}/>
                                    </>
                                ):(
                                    <>
                                    <ProductDiv key={prod.id} id={prod.id} name={prod.name} url={prod.url} price={prod.price} cnt={prod.cnt} video={prod.video} cat={cat} imgwebp={prod.imgwebp} imgjpg={prod.imgjpg}/>
                                    </>
                                )
                            ))}
                            </>
                        )}
                        </>
                    
                        
                    
                    
                </div>
                </>
                )}
            </div>
        </section>



    

    </div>
  )
}
